import React from 'react';
import ReactDOM from 'react-dom';
import AccountApp from '../src/modules/Campaings';

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('account_react');
  const data = JSON.parse(node.getAttribute('data')) || {};
  const dataPoll = parseInt(node.getAttribute('data-poll')) || null;

  let divToBeAppended = document.createElement('div');
  divToBeAppended.id = "root";

  ReactDOM.render(
    <AccountApp userData={data} dataPoll={dataPoll} />,
    document.body.appendChild(divToBeAppended),
  );
});
