import React, {useEffect} from "react";
import "../../assets/styles/Campaigns/index.scss";
import { Footer } from "../../components/footer";
import Header from "../../components/header";
import GetStarted from "./content/GetStarted";
import Hero from "./content/Hero";
import MainBanner from "./content/MainBanner";
import ROI from "./content/ReturnOnInvestment";
import TalkToSales from "./content/TalkToSales";
import Testimonial from "./content/Testimonial";
import TrrustedCompanies from "./content/TrrustedCompanies";
import WhatWeDo from "./content/WhatWeDo";
import PropTypes from "prop-types";
import Clients from "./content/Clients";
import {GrowthBook, GrowthBookProvider,} from "@growthbook/growthbook-react";
import Analytics from "./content/Analytics";
import Industries from "./content/Industries";

const LandingPageContent = () => {
  return (
    <React.Fragment>
      <MainBanner />
      <Hero />
      <TrrustedCompanies />
      <Clients />
      <WhatWeDo />
      <TalkToSales />
      <Analytics />
      <Industries />
      <Testimonial />
      {/*<ROI />*/}
      <GetStarted />
    </React.Fragment>
  );
};

const AccountApp = ({userData}) => {
    const search = window.location.search;
    const email = new URLSearchParams(search).get("email");

    // Create a GrowthBook instance
    const growthbook = new GrowthBook({
        // enableDevMode: true allows you to use the Chrome DevTools Extension to test/debug.
        enableDevMode: true
    });

    useEffect(() => {
        // Load feature definitions from API
        fetch("https://cdn.growthbook.io/api/features/" + window.GROWTHBOOK_FEATURE_KEY)
            .then((res) => res.json())
            .then((json) => {
                growthbook.setFeatures(json.features);
            });

        growthbook.setAttributes({
            id: email
        })
    }, [email]);


    return (
        <GrowthBookProvider growthbook={growthbook}>
            <React.Fragment>
                <Header
                    userData={userData}
                    campaignManager={true}
                />
                <LandingPageContent />
                <Footer/>
            </React.Fragment>
        </GrowthBookProvider>
    )
};

AccountApp.propTypes = {
  userData: PropTypes.object.isRequired,
  dataPoll: PropTypes.number.isRequired,
};

export default AccountApp;
